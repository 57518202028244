const headerMenu = document.querySelector(".header-menu");
const ageModal = document.querySelector(".age-modal");
const confirmBtn = document.querySelector(".btn-confirm");
const denyBtn = document.querySelector(".btn-deny");
const promotionLink = document.querySelector(".promotion-link");

window.scrollTo(0, 0);

promotionLink.addEventListener("click", () => exitIntro("./promotion.html"));

confirmBtn.addEventListener("click", () => {
  ageModal.style.display = "none";
  document.body.style.overflow = "auto";
});

denyBtn.addEventListener("click", () => {
  document.querySelector(".replace-text").innerHTML = `
  <p>Ups... \n Te așteptăm din nou peste câțiva ani!</p>
  `;
});

headerMenu.addEventListener("click", () => exitIntro("./navigation.html"));

function exitIntro(path) {
  document.body.style.animation = `page-outro-other 2s ease-in-out 0s 1`;
  setTimeout(() => {
    window.location.pathname = path;
  }, 500);
}

function checkVerification() {
  const data = localStorage.getItem("is-verified");
  if (!data) return;

  if (JSON.parse(data).verified) {
    ageModal.classList.add("hidden");
    document.body.style.overflow = "auto";
  }

  localStorage.setItem("is-verified", JSON.stringify({ verified: false }));
}
checkVerification();

console.log("TEST VERSION");
